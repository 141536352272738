#footer-container {
    box-shadow: 0px -4px 9px -2px rgba(179,179,179,1);
    -webkit-box-shadow: 0px -4px 9px -2px rgba(179,179,179,1);
    -moz-box-shadow: 0px -4px 9px -2px rgba(179,179,179,1);

    .content{
        .list-items{
            li{
                a{
                    font-size: 13px;
                    text-decoration: none;
                    color: $body-color;
                    &.active{
                        color: $primary;
                    }
                }
            }
        }
        .diffrent-list-items{
            li{
                a{
                    text-decoration: none;
                    color: $body-color;
                    &.active{
                        color: $primary;
                    }
                }
            }
        }
    }
}
/* ============ desktop view ============ */
@include media-breakpoint-up(md) {
    #footer-container {
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;
        .brand{
            text-align: right;
        }
        .content{
            padding-right: 15px;
        }

    }
}