// Fonts
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// Variables
@import 'variables';

// Import bootstrap variables including default color palette and fonts
@import "../../../node_modules/bootstrap/scss/_functions.scss";
@import "../../../node_modules/bootstrap/scss/_variables.scss";

// Bootstrap
@import '~bootstrap/scss/bootstrap';
// Bootstrap Select picker
@import '~bootstrap-select/sass/bootstrap-select.scss';

// layouts
@import 'layout/breadcrumb';
@import 'layout/navbar';
@import 'layout/footer';
// Components
@import 'components/owl-slider';
@import 'components/filters';
@import 'components/faq';
@import 'components/banners';
@import 'components/news';
// @import 'components/pdf-viewer';
// Pages
@import 'pages/home';
@import 'pages/news';

@import 'main';