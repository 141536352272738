.before-heading{
    height: 1rem;
    width: 3rem;
    display: inline-block;
    background-color: $primary;
}
.h1,.h2,.h3,.h4,.h5,.h6{
    color: $secondary;
}
#component-page-title{
    background-color: $white;
}
.btn-gray {
    @include button-variant($gray, $gray, $white);
}  
.btn-primary {
    @include button-variant($primary, $primary, $white);
}  

.custom-container {
    @include make-container(10px);
}

.bootstrap-select{
    .btn{
        border-radius: $input-border-radius;
    }
}
a{
    text-decoration: none
}


@include media-breakpoint-up(sm) {
    .custom-container {
        @include make-container(20%);
    }
}