#lv-breadcrumb{
  .breadcrumb-item{
    a{
      text-decoration: none;
      color: $dark;
    }
    .active{
      font-weight: 500;
    }
  }
}