#home-page {
    .slider {
        .carousel-item {
            max-height: 700px;
            img {
                height: 300px;
                object-fit: cover;
            }
        }
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
            position: relative;
            top: -20%;
            background-size: 150%;
        }
        .content-div {
            background-color: white;
            width: 100%;
            height: 100%;
            .titolo {
                font-size: 1.5rem;
            }
            .sottotitolo {
                font-size: 1.6rem;
                font-weight: 400;
            }
        }
    }
    .newsletter {
        background-position: 80%;
        background-repeat: no-repeat;
        background-size: contain;
        padding-bottom: 2rem;
    }
    .call-to-actions {
        background-color: $white;
        .img-fluid{
            max-width: 60%;
        }
        .btn {
            height: 5rem;
            align-items: center;
            display: flex;
            justify-content: center;
            box-shadow: -5px 10px 5px 0px rgba(219, 219, 219, 0.75);
            -webkit-box-shadow: -5px 10px 5px 0px rgba(219, 219, 219, 0.75);
            -moz-box-shadow: -5px 10px 5px 0px rgba(219, 219, 219, 0.75);
            padding-left: 3rem;
            padding-right: 3rem;
            line-height: 17px;
            font-size: 17px;
            font-weight: bold;
        }
    }
    .home-news{
        .owl-item{
            width: inherit;
        }
    }
    .sponsor{
        .owl-item{
            padding: inherit;
            background-repeat: no-repeat;
            background-position: center;
            max-width: 100%;
            height: 120px;
            background-size: contain;
            background-size: 60%;
        }
    }
}
@include media-breakpoint-up(sm) {
    #home-page {
        .slider {
            .carousel-item {
                img {
                    height: 100%;
                }
            }
            .content-div {
                position: absolute;
                bottom: 140px;
                left: 140px;
                width: 34rem;
                height: 15rem;
                border-radius: 2px;
                -webkit-box-shadow: 0px 10px 13px -7px #000000,
                    5px 5px 15px 5px rgba(0, 0, 0, 0);
                box-shadow: 0px 10px 13px -7px #000000,
                    5px 5px 15px 5px rgba(0, 0, 0, 0);
                .container {
                    padding: 20px !important;
                }
            }
            .carousel-control-prev-icon,
            .carousel-control-next-icon {
                top: 0;
            }
        }
    }
    .sponsor{
        .owl-item{
            padding: 3rem;

        }
    }
}
