$shadow: rgba(219, 219, 219, 0.75);;
#news-page {
    .pagination{
        text-align: center;
        background-color: $white;
    }
    .years-container {
        padding: 20px;
        background-color: $white;
        box-shadow: -5px 10px 5px 0px $shadow;
        -webkit-box-shadow: -5px 10px 5px 0px $shadow;
        -moz-box-shadow: -5px 10px 5px 0px $shadow;
        .list-unstyled {
            li {
                line-height: 2.2rem;
                a {
                    font-size: 20px;
                    text-decoration: none;
                    color: $dark;
                }
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    #news-page {
        .years-container {
            padding-left: 5rem;
            border-top-right-radius: calc(1rem - 1px);
            border-bottom-right-radius: calc(1rem - 1px);
        }
    }
}
// News details
#news-detail-page {
    .years-container {
        padding: 20px;
        background-color: $white;
        box-shadow: -5px 10px 5px 0px $shadow;
        -webkit-box-shadow: -5px 10px 5px 0px $shadow;
        -moz-box-shadow: -5px 10px 5px 0px $shadow;
        .list-unstyled {
            li {
                line-height: 2.2rem;
                a {
                    font-size: 20px;
                    text-decoration: none;
                    color: $dark;
                }
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    #news-detail-page {
        .years-container {
            padding-left: 5rem;
            border-top-right-radius: calc(1rem - 1px);
            border-bottom-right-radius: calc(1rem - 1px);
        }
    }
}
