#banner-divisione {
    .banner {
        .buttons {
            font-size: $small-font-size;
            border: 1px solid $gray;
            border-bottom: none;
            &:last-child {
                border-bottom: 1px solid $gray;
            }
            &.attivo {
                border: 1px solid $dark;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    #banner-divisione {
        .buttons {
            width: 25%;
        }
    }
}

@include media-breakpoint-up(xl) {
    #banner-divisione {
        .buttons {
            border-bottom: 1px solid $gray !important;
            &.attivo {
                border-bottom: 1px solid $dark !important;
            }
        }
    }
}
