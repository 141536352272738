// Body
$body-bg: #f1f1f3;
$body-color: #8c8c8c;

// Colors
$primary: #00955e;
$secondary: #383838;
$red: #ff5565;
$yellow: #e5d700;
$info: #e5d700;
$gray: #8c8c8c;
$light: #fafafa;
$white: #ffffff;
$dark: #222222;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $primary,
  "info":       $info,
  "warning":    $yellow,
  "danger":     $red,
  "light":      $light,
  "dark":       $dark,
  "gray":       $gray
);

// Typography
$font-family-sans-serif: 'Rubik';


$border-radius:               1rem;
$border-radius-sm:            .40rem;
$border-radius-lg:            1rem;


// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius;
$btn-border-radius-sm:        $border-radius-sm;
$btn-border-radius-lg:        $border-radius-lg;

// Forms
$input-bg:                              $white;
$input-border-radius:                   $border-radius - .5rem;
$input-border-radius-lg:                $border-radius-lg - .5rem;