#component-faq {
    .accordion-item {
        border: none;
        border-radius: 0 !important;
        font-size: $small-font-size;
        &:first-of-type {
            .accordion-button {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
        &:last-of-type {
            .accordion-button {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
       
        .accordion-button {
            font-size: 20px;
            font-weight: 700;
            color: $primary;
            background-color: $light;
            box-shadow: none !important;
            &:focus {
                box-shadow: none !important;
                border-color: $dark !important;
            }
            &::after {
                display: none;
            }
            &.collapsed{
              color: $dark;
            }
        }
        .accordion-body {
            // border-bottom: 1px solid $gray;
            font-size: 1rem;
        }
        // .green{
        //   color: $primary;
        // }
        // .dark{
        //  color: white !important; 
        // }
    }
}
@include media-breakpoint-up(sm) {
  #component-faq {
    .accordion-item {
       
        .accordion-button {
            padding-left: 20%;
           
        }
        .accordion-body {
           
            padding-left: 20%;
        }
    }
}
}