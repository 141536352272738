$nav-height: auto;

.navbar-nav .show > .nav-link, .navbar-light .navbar-nav .nav-link.active{
    color: $primary;
}


.navbar-iniziativa,
.navbar-edizioni {
    background-color: $white;
    -webkit-box-shadow: 5px 0px 6px -5px rgba(36, 36, 36, 0.59);
    box-shadow: 0px 2px 5px -3px rgba(36, 36, 36, 0.59);
    .navbar-collapse {
        align-items: flex-start;
    }
    .navbar-toggler {
        margin-right: 1rem;
    }
    .navbar-nav {
        .dropdown-menu {
            position: static;
            border: 0;
        }
    }
}
// Navbar iniziativa
.navbar-iniziativa{
    // Filtro comune classifica
    .filtro-comune{
        #component-page-title{
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            .h3{
                font-size: 1.5rem;
            }
        }
    }
}
/* ============ desktop view ============ */
@include media-breakpoint-up(md) {
    .main-navbar {
        padding: 0;
        height: $nav-height;
        .container-fluid {
            padding: 0;
        }
        .navbar-brand {
            -webkit-box-shadow: 5px 0px 6px -5px rgba(36, 36, 36, 0.59);
            box-shadow: 5px 0px 6px -5px rgba(36, 36, 36, 0.59);
            height: $nav-height;
            width: 200px;
            text-align: center;
            img {
                margin-top: 10px;
            }
        }
        .navbar-brand-2 {
            -webkit-box-shadow: -2px -3px 6px -3px rgba(36, 36, 36, 0.59);
            box-shadow: -2px -3px 6px -3px rgba(36, 36, 36, 0.59);
            height: $nav-height;
            padding: 20px;
            width: 200px;
            justify-content: center;
        }

        .navbar-nav {
            width: 100%;
            margin-top: 30px;
            justify-content: space-around !important;
        }
    }

    .navbar-iniziativa,
    .navbar-edizioni {
        border-radius: 0 1rem 1rem 0;
    }
}
/* ============ desktop view .end// ============ */
