#owl-slides{
  .owl-item{
    img{
      max-height: 350px;
      object-fit: cover; /* or object-fit: contain; */
    }
  }
  .owl-dots {
    // display: flex;
    // justify-content: center;

    .owl-dot {
        width: 15px;
        position: relative;
        top: -40px;
        span {
            width: 10px;
            height: 10px;
            margin: 5px -5px;
            background-color: $light;
            opacity: 0.7;
            display: block;
            -webkit-backface-visibility: visible;
            transition: opacity 0.2s ease;
            border-radius: 30px;
        }
        &.active{
          span{
            opacity: 1;
          }
        }
    }
}
}


// OWL GLOBAL
.owl-prev {
  width: 15px;
  position: absolute;
  font-size: $h1-font-size !important;
  top: 20%;
  margin-left: -20px;
  display: block !important;
  border:0px solid black;
}

.owl-next {
  width: 15px;
  position: absolute;
  font-size: $h1-font-size !important;
  top: 20%;
  right: -25px;
  display: block !important;
  border:0px solid black;
}
.owl-prev i, .owl-next i {transform : scale(1,6); color: $dark;}