#component-filter{
  .filters{
    background-color: $gray;
    input,
    .input-group-text{
      text-align: end;
      font-size: $small-font-size;
      background-color: $gray;
      color: $dark;
      border: 0;
      border-radius: 0;
    }
  }

}