#component-news {
    .news-item {
        padding-bottom: .5rem;
        .card {
            box-shadow: -5px 10px 5px 0px rgba(192, 192, 192, 0.75);
            -webkit-box-shadow: -5px 10px 5px 0px rgba(192, 192, 192, 0.75);
            -moz-box-shadow: -5px 10px 5px 0px rgba(192, 192, 192, 0.75);
            .skew {
                transform: skew(0, 6deg);
                display: block;
                width: 100%;
                background: #fff;
                position: relative;
                top: -22px;
                left: 0px;
                z-index: 0;
                height: 50px;
            }
            // Home slider card CSS
            &:not(.d-flex) {
                .card-img-top{
                    height: 300px;
                }
                .card-body {
                    margin-top: -4rem;
                    z-index: 1;
                }
            }
            // News page css
            &.d-flex {
                margin-top: 1rem;
                margin-bottom: 1rem;
                .card-img-top{
                    object-fit: cover;
                }
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    #component-news {
        .news-item {
            padding-bottom: 1rem;
            .card {
                // Home slider card CSS
                &:not(.d-flex) {
                }
                // News page css
                &.d-flex {
                    height: 250px;
                    .card-img-top{
                        width: 350px;
                        border-top-right-radius: 0;
                        border-bottom-left-radius: calc(1rem - 1px);
                    }
                    .btn-gray{
                        display: inline;
                    }
                }
            }
        }
    }

}